import { Component, OnInit } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';

@Component({
  selector: 'app-about-us',
  templateUrl: './about-us.component.html',
  styleUrls: ['./about-us.component.css']
})
export class AboutUsComponent implements OnInit {

  imageObject = [{
    image: '../../../assets/Chiller3.jpg',
    thumbImage: '../../../assets/Chiller3.jpg',
    title: 'Chilleri'
}, {
    image: '../../../assets/klima2.jpg',
    thumbImage: '../../../assets/klima2.jpg',
    title: 'Klima'
}, {
    image: '../../../assets/chiller.jpg',
    thumbImage: '../../../assets/chiller.jpg',
},{
    image: '../../../assets/rashladna.jpg',
    thumbImage: '../../../assets/rashladna.jpg',
    title: 'Rashladna'
}, {
    image: '../../../assets/rashladna3.jpg',
    thumbImage: '../../../assets/rashladna3.jpg'
}, {
    image: '../../../assets/Chiller3.jpg',
    thumbImage: '../../../assets/Chiller3.jpg',
}];

  constructor(private router: Router) { }

  ngOnInit(): void {
    this.router.events.subscribe((evt) => {
      if (!(evt instanceof NavigationEnd)) {
          return;
      }
      window.scrollTo(0, 0)
  });
  }

}
