<body >
  <div id="nav" >
    <a [routerLink]="['/']" class="logo"><img src="../assets/frigor1.png" alt="Frigor"></a>
    <nav class="nav computer">
      <ul class="nav-ul">
        <li><a [routerLink]="['/']" class="nav-a">Početna</a></li>     
        <li><a [routerLink]="['/contact']" class="nav-a">Kontakt</a></li>
        <li><a [routerLink]="['/about-us']" class="nav-a">O nama</a></li>
        <li><a [routerLink]="['/services']" class="nav-a" id="usluge">Usluge</a>
          <div class="dropdown">
            <a class="dropdown-items nav-a" routerLink="/services" [queryParams]="{ tab: 'rashladne'}">Rashladne komore</a>
            <a class="dropdown-items nav-a" routerLink="/services" [queryParams]="{ tab: 'proracun'}">Usluge proracuna</a>
            <a class="dropdown-items nav-a" routerLink="/services" [queryParams]="{ tab: 'marketi'}">Marketi</a>
            <a class="dropdown-items nav-a" routerLink="/services" [queryParams]="{ tab: 'chilleri'}">Chilleri</a>
            <a class="dropdown-items nav-a" routerLink="/services" [queryParams]="{ tab: 'klima'}">Klima uređaji</a>
          </div>
        </li>
      </ul>
    </nav>
    <nav class="nav mobile">
      <ul class="nav-ul">
        <li><a [routerLink]="['/']" class="nav-a"><img src="../assets/home.png" alt="Pocetna strana"></a></li>     
        <li><a [routerLink]="['/contact']" class="nav-a"><img src="../assets/contact.png" alt="Kontakt strana" ></a></li>
        <li><a [routerLink]="['/about-us']" class="nav-a"><img src="../assets/about-us.png" alt="O nama strana"></a></li>
        <li><a [routerLink]="['/services']" class="nav-a"><img src="../assets/service.png" alt="Servis strana"></a></li>
      </ul>
    </nav>
  </div>
  <router-outlet></router-outlet>
  <div ngxScrollTop class="scroll-to-top" >
    <img src="../assets/up-button.png" >
  </div>
  <div class="footer">
    <div class="inner-footer">
      <div class="logo-container">
        <h1><img src="../assets/frigor-footer.png"  style="height: 60px; width: 250px;"></h1>
      </div>
      <div class="footer-third">
        <h1>Kontakt</h1>
        <a >Office :</a>
        <a class="hover" href="tel:+381 64 115 00 67">+381 64 115 00 67</a>
        <a class="hover"  href="mailto:office@frigor.rs" style="margin-bottom: 15px;">office@frigor.rs</a>
        <a >Kontakt osoba:</a>
        <a class="hover"  href="tel:+381 64 115 00 67">+381 64 115 00 67</a>
        <a class="hover"  href="mailto:goran.nikolic@frigor.rs" style="margin-bottom: 15px;">goran.nikolic@frigor.rs</a>
      </div>
      <div class="footer-third" id="last">
        <h1>Zapratite nas</h1>
          <li><a href="#"><img src="../../../assets/facebook.png"  alt="Facebook"></a></li>
          <li><a href="#"><img src="../../../assets/instagram.png" alt="Instagram"></a></li>
        <span>
          Frigor s.z.r. <br>
          Dragiše Miševića 9 <br>
          24000 Subotica<br>
          Republika Srbija <br>
        </span>   
        <a class="hover"  href="tel:+381 64 115 00 67" style="padding-bottom: 0;">+381 64 115 00 67</a>
        <a class="hover"  href="mailto:office@frigor.rs" style="margin-top: 0px;">office@frigor.rs</a>
        <h1 style="margin-top: 20px;">Servis</h1>
        <a class="hover"  href="mailto:servis@frigor.rs" style="margin-top: 0px;">servis@frigor.rs</a>
      </div>
    
    </div>
  </div>
</body>