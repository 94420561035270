<div class="section-one">
    <mat-tab-group mat-align-tabs="center" [selectedIndex]="tabActive" class="remove-border-bottom">
        <mat-tab label="Rashladne komore" class="mat-tabs-services">
          <main class="container" >
            <section class="card" data-aos="fade-right" data-aos-duration="1200">
              <img src="../../../assets/rashladna.jpg" alt="Rashladna komora" />
              <div>
                <h1 class="naslov">Plusne komore</h1>
                <p class="text">
                  Nudimo proračun, izradu i servis rashladnih komora u režimu rada od 0 do 8 stepeni, za hlađenje svih vrsta voća, mlečnu industriju, mesnu industriju i klanice. 
                  Manje rashladne komore koje su pogodne za čuvanje voća, povrća, jaja, mlečih proizvoda, mesa, suhomesnatih prozvoda, gljiva, cveća pre svega preporučujemo za magacinski prostor u supermarketima.
                </p>
                  <!-- <a href="#" class="btn">Dugme</a> -->
              </div>
            </section>
            <section class="card" data-aos="fade-left" data-aos-duration="1200">
              <img src="../../../assets/rashladna3.jpg" alt="Rashladna komora" />
              <div>
                <h1 class="naslov">Minusne komore</h1>
                <p class="text">
                  Nudimo proračun, izradu i servis rashladnih komora u režimu rada od -18 do -22 stepena, za skladištenje sladoleda, smrznutog povrća, ribe i smrznutog testa.
                   Manje rashladne komore koje su pogodne za čuvanje smrznutih proizvoda preporuka su za magacinski prostor u supermarketima.
                </p>
            <!-- <a href="#" class="btn">Dugme</a> -->
              </div>
            </section>
            <section class="card" data-aos="fade-right" data-aos-duration="1200">
              <img src="../../../assets/rashladna2.jpg" alt="Rashladna komora" />
              <div>
                <h1 class="naslov">Komore za brzo smrzavanje</h1>
                <p class="text">
                  Nudimo proračun, izradu i servis rashladnih komora u režimu rada od -20 do -45 stepeni, za brzo smrzavanje testa, voća, povrća i konditorskih proizvoda. 
                  Prednost ovih komora je činjenica da one sprečavaju širenje mikoorganizama. Komore mogu biti u tunelskoj ili nekoj drugoj izvedbi.
                </p>
                <!-- <a href="#" class="btn">Dugme</a> -->
              </div>
            </section>
          </main>
        </mat-tab>
        <mat-tab label="Usluge proračuna" class="mat-tabs-services">
          <main class="container"  style="margin-bottom: 100px;">
            <section class="card" data-aos="fade-right" data-aos-duration="1200">
              <img src="../../../assets/proracun.jpg" alt="Usluge proracuna" />
              <div>
                <h1 class="naslov">Usluge proračun</h1>
                <p class="text">
                  Uz dobro obučen tim inženjera i iskustvom o pružamo kompletnu uslugu za prilagođene industrijske sisteme za hlađenje i skladištenje, od projektovanja preko montaže i 
                  implementacije projekta do upravljanja projektom. Projektujemo sisteme za hlađenje ili grejanje, skladištenje. 
                  Kvalitetno konstruisanje sistema za hlađenje i grejanje obezbeđeno je izborom prave opreme, i korišćenjem najsavremenije tehnologije.
                </p>
                  <!-- <a href="#" class="btn">Dugme</a> -->
              </div>
            </section>
            
          </main>
        </mat-tab>
        <mat-tab label="Marketi" class="mat-tabs-services">
          <main class="container" >
            <section class="card" data-aos="fade-right" data-aos-duration="1200">
              <img src="../../../assets/marketi_vertikalni.jpg" alt="Vertikalne vitrine" />
              <div>
                <h1 class="naslov">Marketi</h1>
                <p class="text">
                  Vršimo servisiranje i montažu vetikalnih rashladnih vitrina koje obezbeđuju temperaturu od 0 do 8 stepeni. Pogodne su za čuvanje i izlaganje mleka, mlečnih proizvoda, margarina, pića. 
                  Takođe nudimo mogćunost ugradnje i servisiranja minusnih vitrina sa temperaturom od -18 do -22 stepena, pogodne su za čuvanje i izlaganje sladoleda, smrznutog povrća, ribe, smrznutog testa. 
                </p>
                  <!-- <a href="#" class="btn">Dugme</a> -->
              </div>
            </section>
            <section class="card" data-aos="fade-left" data-aos-duration="1200">
              <img src="../../../assets/market_horizontalni.jpg" alt="Horizontalne vitrine" />
              <div>
                <h1 class="naslov">Marketi</h1>
                <p class="text">
                  Vršimo servisiranje i montažu horizontalnih rashladnih vitrina koje obezbeđuju temperaturu od 0 do 8 stepeni. Pogodne su za čuvanje i izlaganje svežeg mesa, ribe, polu trajnih i trajnih delikates proizvoda. 
                </p>
            <!-- <a href="#" class="btn">Dugme</a> -->
              </div>
            </section>

            <section class="card" data-aos="fade-left" data-aos-duration="1200">
              <img src="../../../assets/marketi_self.jpg" alt="Otvorene vitrine" />
              <div>
                <h1 class="naslov">Marketi</h1>
                <p class="text">
                  Vršimo servisiranje i montažu otvorenih (self) rashladnih vitrina koje obezbeđuju temperaturu od 8 do 14 stepeni. Pogodne su za čuvanje i izlaganje voća i povrća. 
                </p>
            <!-- <a href="#" class="btn">Dugme</a> -->
              </div>
            </section>
          </main>
        </mat-tab>
        <mat-tab label="Chilleri" class="mat-tabs-services">
          <main class="container" >
            <section class="card" data-aos="fade-right" data-aos-duration="1200">
              <img src="../../../assets/chiller.jpg" alt="Chilleri" />
              <div>
                <h1 class="naslov">Chilleri</h1>
                <p class="text">
                  Rashladnici vode (Chilleri, rashladni agregati) koriste se za unutrašnju ili vanjsku montažu, dostupni su sa ugrađenom pumpom i spremnikom, a njihova glavna funkcija je rashlađivanje 
                  raznih sistema i uređaja vodom ili glikolom ili kombinacijom navedenog. 
                  Rashladnici vode imaju široku primenu u industriji i proizvodnim pogonima, a njihovo korištenje osigurava dugovečnost i pouzdanost navedenih uređaja. 
                  Chilleri se najčešće koriste u proizvodnji i pakovanju PET ambalaže, u raznim procesima proizvodnje hrane, u livaonicama, u procesima zavarivanja 
                  (klasičnog i induktivnog), vinarija, zlatarskih radnji, raznih oblika pakovanja, te u mnogim drugim primenama koje zahtevaju hlađenje uređaja i opreme.
                </p>
                  <!-- <a href="#" class="btn">Dugme</a> -->
              </div>
            </section>
            <section class="card" data-aos="fade-left" data-aos-duration="1200">
              <img src="../../../assets/chiller2.jpg" alt="Chilleri" />
              <div>
                <h1 class="naslov">Chilleri</h1>
                <p class="text">
                  Primena chillera je takođe i za grejanje prostora, proizvodnih hala i industrijskih pogona.  
                  Odabirom chilera kao način grejanja dobijamo kvalitetno i pouzdano ugrejan prostor. 
                  U toplim letnjim mesecima sa istim sistemom uz samo promenu moda dobijamo mogućnost klimatizacije  - hlađenja prostora.
                </p>
            <!-- <a href="#" class="btn">Dugme</a> -->
              </div>
            </section>
            <section class="card" data-aos="fade-right" data-aos-duration="1200">
              <img src="../../../assets/Chiller3.jpg" alt="Chilleri" />
              <div>
                <h1 class="naslov">Chilleri</h1>
                <p class="text">
                  Široka paleta opcija i dodatne opreme je dostupna upravo iz razloga da bi se udovoljilo svakom industrijskom zahtevu, te da bi se svakoj,
                   prethodno navedenoj primeni, pristupilo individualno. 
                   Naši savetnici u mogućnosti su da Vam ponude optimalno rešenje koje je idelano za Vaše potrebe,
                    čime Vam ostvarujemo osetne uštede i smanjenje troškova u eksploataciji Vašeg Chillera. 
                    Stručni tim servisera vrše instalaciju chillera na odgovarajuću opremu.
                     Uz same chillere nudimo mogućnost instalacije kalorifera (fancoil) unutar objekta za potrebe grejanja i hlađenja prostora.
                </p>
                <!-- <a href="#" class="btn">Dugme</a> -->
              </div>
            </section>
          </main>
        </mat-tab>
        <mat-tab label="Klima uređaji" class="mat-tabs-services" >
          <main class="container" >
            <section class="card" data-aos="fade-right" data-aos-duration="1200">
              <img src="../../../assets/klima_GREE.jpg" alt="Klima" />
              <div>
                <h1 class="naslov">Montaže klima uređaja</h1>
                <p class="text">
                  Montažu zidnih split sistema , kasetnih , kanalskih klima, multi sistema , vrv sistema ,
                  toplotnih pumpi kako u domaćinstvima tako i u javnim ustanovama, server sobama, restoranima, pekarama…
                  Prodajemo, ugrađujemo i servisiramo sve poznate split i multi sisteme klima uređaja koji se mogu naći na našem tržištu.
                  Radimo montažu/demontažu, svih vrsta i modela.
                </p>
                  <!-- <a href="#" class="btn">Dugme</a> -->
              </div>
            </section>
            <section class="card" data-aos="fade-left" data-aos-duration="1200">
              <img src="../../../assets/klima_lg.jpg" alt="Klima" />
              <div>
                <h1 class="naslov">Servis klima uređaja</h1>
                <p class="text">
                  Imamo višegodišnje iskustvo u montaži klima uređaja i popravci svih mogućih kvarova. Dajemo garanciju na uslugu i sve ugrađene delove. 
                  Nudimo povoljno godišnji servis i dezinfekciju klima uređaja sredstvima za koja su upisana u listu biocidnih proizvoda i odobrena od nadležnog ministarstva. 
                  Dajemo preporuku za najbolje klime i način kako da vaša klima radi dugo bez problema. 
                </p>
            <!-- <a href="#" class="btn">Dugme</a> -->
              </div>

              
            </section>

            <section class="card" data-aos="fade-left" data-aos-duration="1200">
              <img src="../../../assets/klima_midea.jpg" alt="Klima" />
              <div>
                <h1 class="naslov">Preporuke u odabiru</h1>
                <p class="text">
                  Naši saveti  Vam mogu pomoći pri odabiru klima uređaja za kvalitetno rashlađen prostor ili grejanje prostorija pri izuzetno niskim temperaturama. 
                </p>
            <!-- <a href="#" class="btn">Dugme</a> -->
              </div>

              
            </section>
          </main>
        </mat-tab>
      </mat-tab-group>
</div>