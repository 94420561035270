


<div class="header-image">
    <div class="image"></div>
    <div  class="header-div">
    <h1 data-aos="fade-up" style="text-align: center;"><img src="../../../assets/frigor1.png" alt="Frigor"></h1>
      <h2  data-aos="fade-up" style="color: #0667B3; text-align: center;">Visoko kvalitetna rashladna i grejna oprema</h2>
     <h2 class="h2-header"><a data-aos="fade-up" data-aos-delay="900" class="header-button" [routerLink]="['/contact']">Kontakt</a></h2>
    </div>
</div>
<div class="section-one">
    <div class="container-section-one">
        <h3 data-aos="fade-up">Rashladni i grejni sistemi</h3>
        <p data-aos="fade-up" data-aos-delay="700" style="padding-bottom: 50px; margin-bottom: 0; "  >

            Klimatizacija i grejanje postali su neophodni za preduzeća svih vrsta. Sistemi za komercijalne primene služe u različite svrhe. U zavisnosti od vrste posla koji vodite, neophodno je zadovoljiti potrebe za hlađenjem i grejanjem.
            <br/>
            <br/>
            Primene opreme za grejanje i hlađenje doživljavaju ekstremno brzu transformaciju! Zahtevi za većom energetskom efikasnošću, prelazak na rashladna sredstva sa malim GWP i drugi pokretači održivosti stvaraju nove izazove. 
            Osim samih uređaja još jedan element koji će doprineti energetskoj efikasnosti su mere koje preduzimate prilikom instaliranja i upotrebe vašeg klima uređaja ili sistema grejanja.
        <br/>
        <br/>
            Ali je tu FRIGOR da pomogne. Od industrijskog grejanja i hlađenja do komercijalnih rashladnih i stambenih poslova, imamo iskustva da udovoljimo svim vašim potrebama. Za sve vaše potrebe Vam stojimo na usluzi, dovoljno je da nas kontaktirate!

         </p>
    </div>
</div>
<div class="section-two">
    <h3 style="background-color: white;" data-aos="fade-up" class="podnaslov">U ponudi</h3>
    <div class="cards" data-aos="fade-up" data-aos-delay="700" style="padding-bottom: 50px;">
        <div class="card card-top">
            <div class="card-image">
                <img src="../../../assets/klima.png" alt="Klima">
            </div>
            <div class="card-text">
            
                <h1 class="card-text-heading">Klima uređaji</h1>
                <div class="text-div-cards">
                <p class="card-text-p">
                    Pronalaženje optimalnog koncepta sistema i rešenja za namenu vašeg prostora. Uz maksimalnu uštedu energije i najvećom efikasnošću. 
                </p>
                </div>
                <a href="" class="card-text-button bottom-button" routerLink="/services" [queryParams]="{ tab: 'klima'}" id="animationButton">Detaljnije</a>
            </div>
        </div>
        <div class="card card-top">
            <div class="card-image">
                <img src="../../../assets/chiller.jpg" alt="Chiller">
            </div>
            <h1 class="card-text-heading">Chilleri</h1>
            <div class="text-div-cards">
                <p class="card-text-p">Nudimo razne rashladne uređaje i chilleri za industrijsku primenu, pomažemo da se u procesima proizvodnje postižu optimalne temperature.</p>
            </div>
                <a href="" class="card-text-button bottom-button" routerLink="/services" [queryParams]="{ tab: 'chilleri'}" id="animationButton">Detaljnije</a>
        </div>
        <div class="card card-top">
            <div class="card-image">
                <img src="../../../assets/rashladna.jpg" alt="Rashladne komore">
            </div>
            <h1 class="card-text-heading">Rashladne komore</h1>
                <div class="text-div-cards">
                    <p class="card-text-p">
                        Rashladne komore, namenjene su kako za čuvanje prehrambenih proizvoda, tako i za čuvanje zamrznutih namirnica i brzo zamrzavanje istih.

                    </p>
                </div>
                    <a href="" class="card-text-button bottom-button" routerLink="/services" [queryParams]="{ tab: 'rashladne'}" id="animationButton">Detaljnije</a>
                
                
        </div>
        <div class="card">
            <div class="card-image">
                <img src="../../../assets/proracun.jpg" alt="Proracun">
            </div>
            <h1 class="card-text-heading">Usluge proračuna</h1>
            <div class="text-div-cards">
                <p class="card-text-p">
                    Možemo da projektujemo snagu rashladne i grejne opreme u skladu sa vašim specifikacijama sa pouzdanim podacima i savetujemo Vas o najboljem mogućem izboru, opremanju i broju jedinica.
                </p>
            </div>
                <a href="" class="card-text-button bottom-button" routerLink="/services" [queryParams]="{ tab: 'proracun'}" id="animationButton" >Detaljnije</a>

        </div>
        <div class="card">
            <div class="card-image">
                <img src="../../../assets/marketi.jpg" alt="Marketi">
            </div>
            <h1 class="card-text-heading">Marketi</h1>
            <div class="text-div-cards">
                <p class="card-text-p">
                   
                    Najvažniji faktor prilikom skladištenja prehrambenih proizvoda su konstantna temperatura, velika sigurnost u slučaju kvarova i niski operativni troškovi.
                    

                </p>
            </div>
                <a href="" class="card-text-button bottom-button" routerLink="/services" [queryParams]="{ tab: 'marketi'}" id="animationButton">Detaljnije</a>
        </div>
        <div class="card last-card" [routerLink]="['/services']">
            <!-- <div class="card-image">
                <img src="../../../assets/klima.jpg" alt="">
            </div> -->
            <div class="div-ceo-asortiman">
            <h1 class="card-last-text" >Ceo asortiman usluga</h1>
        </div>
        </div>
    </div>
</div>
<div class="section-three">
    <h3  data-aos="fade-up" class="podnaslov">Reference</h3>
    <div class="reference-cards"  data-aos="fade-up" data-aos-delay="700">
        <div class="reference-card"><img src="../../../assets/frikom.png" alt="Frikom"></div>
        <div class="reference-card"><img src="../../../assets/gebi.png" alt="Gebi"></div>
        <div class="reference-card"><img src="../../../assets/pionir.png" alt="Pionir"></div>
        <div class="reference-card"><img src="../../../assets/swarovski.png" alt="Swarovski"></div>
        <div class="reference-card"><img src="../../../assets/infostud.png" alt="Infostud"></div>
        <div class="reference-card"><img src="../../../assets/atbsever.png" alt="Atbsever"></div>
      </div>
      <h3><a [routerLink]="['/about-us']" class="card-text-button"  data-aos="fade-up" data-aos-delay="700" id="animationButton"  >Detaljnije</a></h3> 
</div>
<div *ngIf="false" class="section-four">
    <h3 style="background-color: white;"  data-aos="fade-up" >Sertifikati</h3>
    <div class="text-section-four"  data-aos="fade-up" data-aos-delay="700">
        <p style="text-align: center;">Lorem ipsum dolor sit amet consectetur adipisicing elit. Adipisci voluptates fuga quam dolorum unde eos cum possimus, molestias sint omnis esse odit placeat perferendis modi, autem quasi laborum vero sequi.     </p>
    </div>
    <div class="certificat-cards"  data-aos="fade-up" data-aos-delay="700">
        <div class="certificat-card"><img src="../../../assets/isoqar.png" alt="isoqar"></div>
        <div class="certificat-card"><img src="../../../assets/isoqar.png" alt="isoqar"></div>
        <div class="certificat-card"><img src="../../../assets/isoqar.png" alt="isoqar"></div>
    </div>
</div>