import { Component , HostListener, ViewChild, ElementRef, OnInit, AfterViewInit,} from '@angular/core';
import { ViewportScroller} from '@angular/common';
import {Router} from "@angular/router";

import * as AOS from 'aos';
@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent {
  title = 'frigo';
  shouldShow: boolean = false;
  navbarVariable:boolean = false;
  
  constructor(private router: Router) { 
  }

  ngOnInit(){
    AOS.init();
  }
}
