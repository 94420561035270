import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from "@angular/router";
import { Location, LocationStrategy, PathLocationStrategy } from '@angular/common';

@Component({
  selector: 'app-services',
  templateUrl: './services.component.html',
  styleUrls: ['./services.component.css'],
  providers: [Location, { provide: LocationStrategy, useClass: PathLocationStrategy }]

})
export class ServicesComponent implements OnInit {
  tabActive:number=0;
  constructor(
    private router: Router,
    private route: ActivatedRoute,
  ) {
  }

  ngOnInit() {
    this.route.queryParams.subscribe((params) => {
      if (params.tab) {
        if(params.tab=='rashladne'){
          this.tabActive=0;
        }else if(params.tab=='proracun'){
          this.tabActive=1;
        }else if(params.tab=='marketi'){
          this.tabActive=2;
        }else if(params.tab=='chilleri'){
          this.tabActive=3;
        }else if(params.tab=='klima'){
          this.tabActive=4;
        }else{
          this.tabActive=0;
        }
      }
    });
  }

}
