
<div class="section-one">
    <div class="container">
        <div class="row">
          <div class="card">
            <div class="card-header">
              <h1>Kontakt osoba</h1>
            </div>
            <div class="card-body">
                <ul class="info">
                    <li>
                        <span><img src="../../../assets/mail.png" alt="goran.nikolic@frigor.rs" ></span>
                        <span class="image-span"><a class="info-a" href="mailto:goran.nikolic@frigor.rs">goran.nikolic@frigor.rs</a></span>
                    </li>
                    <li>
                        <span><img src="../../../assets/call.png" alt="+381 64 115 00 67" ></span>
                        <span class="image-span"><a href="tel:+381 64 115 00 67" class="info-a">+381 64 115 00 67</a></span>
                    </li>
                </ul>
            </div>
          </div>
          
          <div class="card">
            <div class="card-header">
              <h1>Servis</h1>
            </div>
            <div class="card-body">
                <ul class="info">
                    <li>
                        <span><img src="../../../assets/mail.png" alt="servis@frigor.rs"></span>
                        <span class="image-span"><a class="info-a" href="mailto:servis@frigor.rs">servis@frigor.rs</a></span>
                    </li>
                    <li>
                        <span><img src="../../../assets/call.png" alt="+381 64 115 00 67" ></span>
                        <span class="image-span"><a href="tel:+381 64 115 00 67" class="info-a">+381 64 115 00 67</a></span>
                    </li>
                </ul>
            </div>
          </div>
          <div class="card last">
            <div class="card-header">
              <h1>Sedište</h1>
            </div>
            <div class="card-body">
                <ul class="info">
                    <li>
                        <span><img src="../../../assets/mail.png" alt="office@frigor.rs"></span>
                        <span class="image-span"><a class="info-a" href="mailto:office@frigor.rs">office@frigor.rs</a></span>
                    </li>
                    <li>
                        <span><img src="../../../assets/call.png" alt="+381 64 115 00 67"></span>
                        <span class="image-span"><a href="tel:+381 64 115 00 67" class="info-a">+381 64 115 00 67</a></span>
                    </li>
                    <li>
                        <span><img src="../../../assets/mail.png" alt="servis@frigor.rs"></span>
                        <span class="image-span"><a class="info-a" href="mailto:servis@frigor.rs">servis@frigor.rs</a></span>
                    </li>
                </ul>
            </div>
          </div>
          
        </div>
      </div>
</div>
<div class="section-two">
    <div id="container" >
       
        <div class="contact-left">
            <header class="header-contact">
                <h1>OBRAZAC ZA KONTAKT</h1>
                <p class="kontakt-text">Ukoliko imate bilo kakva pitanja u vezi naših usluga i proizvoda možete nas kontaktirati pomoću ovog obrasca.</p>
            </header>
        <form>
            <fieldset>
            <legend>Polja označena * su obavezna</legend>
            <br/>
            <div id="part-1">
                <div class="contact-inputs">
                <div class="inputs-div">
                <label for="firstAndLast-name">Ime i Prezime</label>
                <input type="text" name="firstAndLast-name" id="firstAndLast-name" required>
            </div>
                <br/>
                <div class="inputs-div">
                <label for="phone-number" class="not-required">Broj telefona</label>
                <input type="tel" name="phone-number" id="phone-number" class="half-input" > 
            </div>
            <br/>
            <div class="inputs-div">
            <label for="email">Vaša email adresa</label>
            <input type="email" name="email" placeholder="primer@email.com" id="email" required>
        </div>
                <br/>
                <div class="inputs-div">
                <label for="date">Datum</label>
                <input type="date" name="date" min="2021-01-01" id="date" required class="half-input">
            </div>
           
                <br>
                </div>
                <div class="contact-message">
                <label for="poruka">Poruka</label>
                <textarea rows="5" cols="50" id="message" tabindex="-1" alt="Poruka" name="poruka"></textarea>
                 </div>
            </div>
            <br/><br/>
            <input type="submit" id="submitButton" name="submitButton" value="Pošalji" >
            <input type="reset" id="resetButton" value="Obriši" tabindex="-1">
            </fieldset>
        </form>
        </div>
        <div class="contact-right">
        <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d691.7356105274204!2d19.676061729250396!3d46.09212469869465!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x4743612dc48bd02d%3A0x137939224519f00d!2z0JTRgNCw0LPQuNGI0LUg0JzQuNGI0L7QstC40ZvQsCA5LCDQodGD0LHQvtGC0LjRhtCwIDI0MTEx!5e0!3m2!1ssr!2srs!4v1612746056402!5m2!1ssr!2srs" width="100%" height="450" frameborder="0" style="border:0;" allowfullscreen="" aria-hidden="false" tabindex="0"></iframe>
         </div>
    </div>
</div>
<!-- <div class="section-three">
    <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d691.7356105274204!2d19.676061729250396!3d46.09212469869465!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x4743612dc48bd02d%3A0x137939224519f00d!2z0JTRgNCw0LPQuNGI0LUg0JzQuNGI0L7QstC40ZvQsCA5LCDQodGD0LHQvtGC0LjRhtCwIDI0MTEx!5e0!3m2!1ssr!2srs!4v1612746056402!5m2!1ssr!2srs" width="100%" height="450" frameborder="0" style="border:0;" allowfullscreen="" aria-hidden="false" tabindex="0"></iframe>
</div>
<div class="selection-four">
   
</div> -->
