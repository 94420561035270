<div class="section-one">
	<figure>
		<img src="../../../assets/background.jpg" alt="Frigor">
	</figure>
</div>
<div class="section-two">
    <div class="container-section-one">
        <h3 data-aos="fade-up" style="background-color: white;">Frigor</h3>
        <p data-aos="fade-up" data-aos-delay="700">
            FRIGOR je osnovan 01.06.2008. godine. Imamo dugogodišnje iskustvo sa montažom i održavanjem rashladnih i HVAC sistema. <br/> <br/> <br/>
            Sa naše tri mobilne servisne ekipe održavamo sisteme za veliki zadovoljnih klijenata kako sa industrijskim postrojenjima tako i sa individualnim. <br/><br/> <br/>
            Za klijente sa kojima imamo ugovore o održavanju rashladne opreme, posedujemo i dežurnu službu koja je dostupna 24/7.<br/><br/> <br/>
             Posedujemo i veliki lager rezervnih delova, što nam omogućava rešavanje kvara u najkraćem vremenskom roku.<br/><br/> <br/>
            Za bilo koji vaš zahtev slobodno nas kontaktirajte putem mail-a office@frigor.rs ili preko telefona : 0641150067.<br/><br/> <br/>
            

        </p>
    </div>
</div>
<div class="section-three">
    <h3  data-aos="fade-up">Reference</h3>
    <div class="reference-cards"  >
    
        <div class="reference-card" data-aos="fade-up" data-aos-delay="700" data-aos-duration="1000" ><img src="../../../assets/frikom.png" alt="Frikom"></div>
        <div class="reference-card" data-aos="fade-up" data-aos-delay="700" data-aos-duration="1000" ><img src="../../../assets/reference/gebi.jfif" alt="Gebi"></div>
        <div class="reference-card" data-aos="fade-up" data-aos-delay="700" data-aos-duration="1000"><img src="../../../assets/pionir.png" alt="Pionir"></div>
        <div class="reference-card" data-aos="fade-up" data-aos-delay="700" data-aos-duration="1000"><img src="../../../assets/swarovski.png" alt="Swarovski"></div>
        <div class="reference-card" data-aos="fade-up" data-aos-delay="700" data-aos-duration="1000"><img src="../../../assets/infostud.png" alt="Infostud"></div>
        <div class="reference-card" data-aos="fade-up" data-aos-delay="700" data-aos-duration="1000"><img src="../../../assets/atbsever.png" alt="Atbsever"></div>
        
        <div class="reference-card" data-aos="fade-up" data-aos-delay="700" data-aos-duration="1000"><img src="../../../assets/bolnica-subotica.png" alt="Bolnica Subotica"></div>
        <div class="reference-card" data-aos="fade-up" data-aos-delay="1000" data-aos-duration="1000"><img src="../../../assets/reference/ak.png" alt="Ak"></div>
        <div class="reference-card" data-aos="fade-up" data-aos-delay="1000" data-aos-duration="1000"><img src="../../../assets/reference/conti.jfif" alt="Continental"></div>
        <div class="reference-card" data-aos="fade-up" data-aos-delay="1000" data-aos-duration="1000"><img src="../../../assets/reference/imlek.png" alt="Imlek"></div>
        <div class="reference-card" data-aos="fade-up" data-aos-delay="1000" data-aos-duration="1000"><img src="../../../assets/reference/mack.png" alt="Mack"></div>
        <div class="reference-card" data-aos="fade-up" data-aos-delay="1000" data-aos-duration="1000"><img src="../../../assets/reference/maglic.jfif" alt="Mlekara Maglic"></div>


      </div>
      <div class="reference-cards-last" style="margin-top: 20px;">
        <div class="reference-card" data-aos="fade-up" data-aos-delay="1000" data-aos-duration="1000"><img src="../../../assets/reference/maralogo.png" alt="Pekara Mara"></div>
        <div class="reference-card" data-aos="fade-up" data-aos-delay="1000" data-aos-duration="1000"><img src="../../../assets/reference/perfec-vision-subotica_0.jpg" alt="Perfect Vision Subotica"></div>
        <div class="reference-card  last-row" data-aos="fade-up" data-aos-delay="1300" data-aos-duration="1000"><img src="../../../assets/reference/sanja.jfif" alt="Sanja"></div>
        <div class="reference-card  last-row" data-aos="fade-up" data-aos-delay="1300" data-aos-duration="1000"><img src="../../../assets/reference/sveti vrac.png" alt="Sveti Vraci"></div>
        <div class="reference-card  last-row" data-aos="fade-up" data-aos-delay="1300" data-aos-duration="1000"><img src="../../../assets/reference/vremeplov.png" alt="Vremeplov Poslasticarnica"></div>
      </div>
</div>
<div class="section-four">
    <h3  data-aos="fade-up">Neki od naših radova</h3>
    <div class="slider-image">
    <ng-image-slider #nav
    [images]="imageObject"
    [infinite]="false"
    [imageSize]="{width: '400px', height: '300px', space: 4}"
    [autoSlide]="1"

    slideImage="1"></ng-image-slider>
    </div>  
</div>
